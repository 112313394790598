<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <p>
                {{ description }}
            </p>
            <v-textarea
                v-model="details"
                outlined
                color="accent"
                class="mt-3"
                :label="inputLabel || 'textarea'"
                hide-details
            />
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        inputLabel: {
            type: String,
            required: false,
            default: ''
        },
        detailRedact: {
            type: String,
            required: false,
            default: ''
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showModal: true,
            details: '',
            subject: ''
        }
    },
    created() {
        if (this.detailRedact.length) {
            this.details = this.detailRedact
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', {
                details: this.details
            })
        }
    }
}
</script>

<style>

</style>
