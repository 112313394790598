<template>
    <div :class="className">
        <v-slide-x-reverse-transition>
            <v-card
                v-click-outside="closeSidePanel"
                elevation="2"
                class="side_panel px-5 viewer"
                :style="`max-width: ${containerWidth}`"
            >
                <div class="d-flex justify-space-between align-center w-full py-5 viewer-title">
                    <p class="text-subtitle-1 mb-0">
                        {{ title }}
                    </p>
                    <v-btn
                        icon
                        @click="closeSidePanel"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </div>
                <v-divider />
                <div class="side_panel_scroll">
                    <slot name="body" />
                </div>
            </v-card>
        </v-slide-x-reverse-transition>
        <slot name="modals" />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        className: {
            type: String
        },
        containerWidth: {
            type: String,
            required: false,
            default: '60%'
        }
    },
    methods: {
        closeSidePanel(e) {
            if (e.target.closest('.v-dialog')) return // exception for the opened modals inside the viewer
            if (e.target.closest('.v-menu__content')) return // exception for the per_page selection.
            this.$emit('closeSidePanel')
        }
    }
}
</script>

<style lang="scss">
    .side_panel {
        // max-width: 60%;
        width: 100%;
        position: fixed;
        z-index: 9;
        top: 0;
        bottom: 0;
        right: 0;
    }
    .side_panel_scroll {
        height: calc(100vh - 80px) !important;
        overflow: auto !important;
        overflow-x: hidden !important;
        position: relative;
        .comment-logs {
            max-height: initial !important;
        }
    }
    .viewer {
        @media (max-width: 850px) {
            max-width: 100% !important;
        }
    }
</style>
